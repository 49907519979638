import './bootstrap'
import '../css/app.css'
import { createInertiaApp, usePage, router } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, DefineComponent, h } from 'vue'
import {
  init,
  browserTracingIntegration,
  extraErrorDataIntegration,
  replayIntegration,
  startBrowserTracingPageLoadSpan,
  startBrowserTracingNavigationSpan,
  setUser,
  SEMANTIC_ATTRIBUTE_SENTRY_SOURCE
} from '@sentry/vue'
import { type Integration } from '@sentry/types'

const appName = import.meta.env.VITE_APP_NAME || 'Arc by Lumin Sports'

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
  setup ({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })

    if (import.meta.env.VITE_SENTRY_DSN) {
      const integrations: Integration[] = [
        extraErrorDataIntegration({ depth: 10 })
      ]

      if (import.meta.env.VITE_SENTRY_REPLAY_ERRORS_SAMPLE_RATE || import.meta.env.VITE_SENTRY_REPLAY_SESSIONS_SAMPLE_RATE) {
        integrations.push(
          replayIntegration({
            maskAllText: false,
            maskAllInputs: true,
            networkDetailAllowUrls: [window.location.origin],
          })
        )
      }

      if (import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) {
        integrations.push(
          browserTracingIntegration({
            // disable automatic span creation
            instrumentNavigation: false,
            instrumentPageLoad: false,
          })
        )
      }

      const client = init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.MODE,
        logErrors: import.meta.env.MODE !== 'production',
        integrations,
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) : 0.0,
        replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SESSIONS_SAMPLE_RATE ? parseFloat(import.meta.env.VITE_SENTRY_REPLAY_SESSIONS_SAMPLE_RATE) : 0.0,
        replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ERRORS_SAMPLE_RATE ? parseFloat(import.meta.env.VITE_SENTRY_REPLAY_ERRORS_SAMPLE_RATE) : 0.0,
        ignoreErrors: [
          // Random plugins/extensions
          'top.GLOBALS',
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          'originalCreateNotification',
          'canvas.contentDocument',
          'MyApp_RemoveAllHighlights',
          'http://tt.epicplay.com',
          'Can\'t find variable: ZiteReader',
          'jigsaw is not defined',
          'ComboSearch is not defined',
          'http://loading.retry.widdit.com/',
          'atomicFindClose',
          // Facebook borked
          'fb_xd_fragment',
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
          // reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268
          'bmi_SafeAddOnload',
          'EBCallBackMessageReceived',
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          'conduitPage',
          // AmCharts warnings
          'EventDispatcher is disposed',
          // VueRouter Navigation Guards
          'NavigationDuplicated',
          'NavigationCancelled',
          'NavigationRedirected',
          'NavigationAborted',
          new RegExp('Avoided redundant navigation to current location: .*'),
          new RegExp('Navigation cancelled from .* to .* with a new navigation'),
          new RegExp('Redirected when going from .* to .* via a navigation guard'),
          new RegExp('Navigation aborted from .* to .* via a navigation guard'),
          // Random service worker errors
          new RegExp('Failed to update a ServiceWorker for scope .*: An unknown error occurred when fetching the script'),
          new RegExp('Script .*/service-worker.js load failed'),
          'InvalidStateError: newestWorker is null',
          // Random network errors
          'Network Error'
        ],
        denyUrls: [
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i // Cacaoweb
        ],
      })

      let sentryUser = props.initialPage?.props?.auth?.user ? {
        id: props.initialPage.props.auth.user.id,
        email: props.initialPage.props.auth.user.email,
        username: props.initialPage.props.auth.user.username,
      } : null

      setUser(sentryUser)

      router.on('finish', () => {
        const page = usePage()
        if (page.props.auth.user?.id !== sentryUser?.id) {
          sentryUser = page.props.auth.user ? {
            id: page.props.auth.user.id,
            email: page.props.auth.user.email,
            username: page.props.auth.user.username,
          } : null

          setUser(sentryUser)
        }
      })

      if (import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE && client) {
        let pageLoadSpan = startBrowserTracingPageLoadSpan(client, {
          name: window.location.pathname,
          op: 'pageload',
          attributes: {
            [SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "url",
          },
        })

        router.on('navigate', (event) => {
          // Make sure that the pageload span uses the route name
          // After that, each route change should trigger a navigation span (which will automatically finish the previous one)
          if (pageLoadSpan) {
            pageLoadSpan.updateName(event.detail.page.url)
            pageLoadSpan.setAttribute(
              SEMANTIC_ATTRIBUTE_SENTRY_SOURCE,
              "route",
            )
            pageLoadSpan = undefined
          } else {
            startBrowserTracingNavigationSpan(client, {
              op: "navigation",
              name: event.detail.page.url, // or what the name of the span should be
              attributes: {
                [SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "route",
              },
            })
          }
        })
      }
    }

    app
      .use(plugin)
      .mount(el)
  },
  progress: {
    color: '#E1FE45'
  }
})
